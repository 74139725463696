import React, { useEffect, useState } from 'react';
import { TextField, CircularProgress, useMediaQuery, useTheme } from '@material-ui/core';
import { useFieldArray, useForm } from 'react-hook-form';
import { validateCaptcha } from 'react-simple-captcha';
import { Box, Container, inputLabelProps, inputMUIDefaultStyles, InputsBox } from '../../common/styles/FormStyles';
import { Button } from '../../common/styles';
import { DynamicQuestionsBox, SelectInput, PatientCodeAndBirthdayInputBox } from '../../common/components';
import { PatientPreferenceComunicationInput } from './PatientPreferenceComunicationInput';
import { AddressBoxInputs } from './AddressBoxInputs';
import { PasswordBoxInputs, UpdatePasswordBoxInputs } from './PasswordBoxInputs';
import { ContactBoxInputs } from './ContactBoxInputs';
import { TermsBoxInputs } from './TermsBoxInputs';
import { captchaError, localStorageMethods } from '../../common/helpers';
import { DynamicResponse, IDefaultValueRegistrationFormData, IMedicineFormData, PatientFormDataPropertieNamesEnum } from '../contracts';
import { ErrorsTypeEnum, FlagQuestionTypeEnum, IPatientCrendentials, IUseFormData, LocalStorageKeysEnum, PageModeEnum, IAuthUser } from '../../common/contracts';
import { patientNameValidation } from '../../common/validations/forms';
import { dynamicQuestionsService, patientDataService, signupPatientService } from '../../../../services';
import { checkIfPatientNeedsCaregiver, genderList, handleDefaultValueData, patientFormAlertMessages, prepareCreateBodyData, prepareUpdateBodyData, setDefaultValues } from '../helpers';
import { dynamicQuetionHelpers } from '../../common/helpers/dynamicQuestions';
import { useLoader } from '../../../../contexts/patient-portal/Loader.context';
import { useAlert } from '../../../../contexts/patient-portal/Alert.context';
import { RoutesEnum } from '../../../../constants';
import envConfig from '../../../../config/environment-config';
import Captcha from '../../../../components/Captcha.tsx';
import { useHistory, useLocation } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useDialogue } from '../../../../contexts/patient-portal/Dialogue.context';
import { UpdatePatientParams } from '../../signup/contracts/signupInterface';
import { useAuth } from '../../../../contexts/auth.context';

export function PatientForm({
  mode,
}: {
  mode: PageModeEnum,
}): JSX.Element {
  const queryClient = useQueryClient();
  const { showDialogue } = useDialogue();
  const [patientLegalAgeError, setPatientLegalAgeError] = useState<boolean>(false);
  const [callApi, setCallApi] = useState<boolean>(false);
  const { showLoader, hideLoader } = useLoader();
  const { showAlert, hideAlert } = useAlert();
  const { push } = useHistory();
  const location = useLocation();
  const [isLoadingDefaultValue, setIsLoadingDefaultValue] = useState<boolean>(true);
  const pathname = location.pathname;
  const origin = pathname.includes('/unimed') ? '/unimed' : '';
  const patientCredentials = localStorageMethods.getItem<IPatientCrendentials>(LocalStorageKeysEnum.PATIENT_CREDENTIALS);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const medicineFormData = localStorageMethods
    .getItem<IMedicineFormData>(LocalStorageKeysEnum.MEDICINE_FORM_DATA);
  const {
    data: questions,
    error,
  } = dynamicQuestionsService.findManyByProgramCode(envConfig.boehringerProgramCode, pathname);
  
  const handleCreatePatientSuccess = (data: IAuthUser) => {
    localStorage.removeItem(LocalStorageKeysEnum.MEDICINE_FORM_DATA);
    localStorageMethods.setItem<IPatientCrendentials>(LocalStorageKeysEnum.PATIENT_CREDENTIALS, {
      access_token: data?.access_token,
      refresh_token: data?.refresh_token,
      healthPassportId: data?.userinfo?.uuid,
      userId: data?.userinfo?.userId,
      email: data?.userinfo?.email,
      firstName: data?.userinfo?.firstName,
      lastName: data?.userinfo?.lastName,
      fullName: data?.userinfo?.fullName,
      avatar: data?.userinfo?.avatar,
      programId: envConfig.boehringerProgramCode,
      isUnimed: window.location.pathname.includes('/unimed')
    });
    showAlert(
      patientFormAlertMessages.success.title,
      patientFormAlertMessages.success.subTitle,
      patientFormAlertMessages.success.status,
    );
    setTimeout(() => hideAlert(), 3000);
    setTimeout(() => window.location.href = '/', 3200);
  }

  const createPatient = async (registrationFormData: any) => {
    if (!medicineFormData) {
      return showAlert(
        patientFormAlertMessages.missingMedicineFormDataError.title,
        patientFormAlertMessages.missingMedicineFormDataError.subTitle,
        patientFormAlertMessages.missingMedicineFormDataError.status,
      );
    }

    if (!questions) {
      return showDialogue('Dados do paciente', 'Ops! Tivemos um erro ao obter os dados do paciente, tente novamente mais tarde!', 'error');
    }

    const userCaptchaValue = (document.getElementById('patient_captcha_input') as HTMLInputElement)?.value;
    if (!validateCaptcha(userCaptchaValue)) {
      return showAlert(captchaError.title, captchaError.subTitle, 'error');
    }

    const preparedData = prepareCreateBodyData({ medicineFormData, registrationFormData });
    const result = await signupPatientService.resgister(preparedData, pathname);
    handleCreatePatientSuccess(result?.data);
  };

  const handleUpdatePatientSuccess = (body: UpdatePatientParams) => {
    if (patientCredentials) {
      localStorageMethods.setItem<IPatientCrendentials>(LocalStorageKeysEnum.PATIENT_CREDENTIALS, {
        ...patientCredentials,
        email: body.user.email,
        fullName: body.user.name,
      })
    }
    
    setTimeout(() => hideAlert(), 3000);
    setTimeout(() => push(`${RoutesEnum.PATIENT_PANEL_DASHBOARD}${origin}`), 3200);
    
    return showAlert(
      patientFormAlertMessages.successUpdatePatient.title,
      patientFormAlertMessages.successUpdatePatient.subTitle,
      patientFormAlertMessages.successUpdatePatient.status,
    );
  }

  const updatePatient = async (registrationFormData: IDefaultValueRegistrationFormData) => {
    if (!questions) {
      return showDialogue('Dados do paciente', 'Ops! Tivemos um erro ao obter os dados do paciente, tente novamente mais tarde!', 'error');
    }
    const body = prepareUpdateBodyData(
      registrationFormData,
      patientCredentials?.healthPassportId || '',
    );

    await patientDataService.updatePatientData(body, pathname);
    await queryClient.invalidateQueries([LocalStorageKeysEnum.PATIENT_DATA, patientCredentials?.healthPassportId || '']);
    handleUpdatePatientSuccess(body);
  };

  const getCreateDefaultValue = () => ({
    cpf: medicineFormData?.cpf,
    birthDate: medicineFormData?.birthDate,
  });

  const getUpdateDefaultValue = () => {
    const { data: patientData, isLoading } = patientDataService.getPatientData(
      Number(envConfig.boehringerProgramCode),
      patientCredentials?.healthPassportId || '',
      setCallApi,
    );

    useEffect(() => {
      setIsLoadingDefaultValue(isLoading);
    }, [isLoading]);

    return handleDefaultValueData(patientData?.data);
  };

  /* eslint-disable */
  const modeOptions = {
    [PageModeEnum.CREATE.toString()]: {
      buttonName: 'Continue',
      dataDestiny: createPatient,
      getDefaultValue: getCreateDefaultValue as any,
      termsComponent: (formData: IUseFormData) => <TermsBoxInputs formData={formData} />,
      passwordComponent: (formData: IUseFormData) => <PasswordBoxInputs formData={formData} />,
      patientPreferenceComunicationInputComponent:
        (formData: IUseFormData) => <PatientPreferenceComunicationInput formData={formData} />,
      cancelButton: () => <></>,
      captcha: () => <Captcha idName='patient_captcha_input' />,
    },
    [PageModeEnum.UPDATE.toString()]: {
      buttonName: 'Salvar',
      dataDestiny: updatePatient,
      getDefaultValue: getUpdateDefaultValue as any,
      termsComponent: () => <></>,
      passwordComponent: (formData: IUseFormData) => <UpdatePasswordBoxInputs formData={formData} />,
      patientPreferenceComunicationInputComponent: () => <></>,
      cancelButton: () => {
        return (
          <Button type='cancel' onClick={() => push(`${RoutesEnum.PATIENT_PANEL_DASHBOARD}${origin}`)}>
            <button>
              Cancelar alterações
            </button>
          </Button>
        )
      },
      captcha: () => <></>,
    },
  };
  /* eslint-disable */

  const defaultValue = modeOptions[mode]?.getDefaultValue();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control,
    setValue,
    watch,
    getValues,
    unregister,
  } = useForm();
  const formData = {
    register,
    handleSubmit,
    errors,
    setError,
    control,
    setValue,
    watch,
    getValues,
    unregister,
  };
  const { fields, append, remove } = useFieldArray({
    control,
    name: PatientFormDataPropertieNamesEnum.USER_DYNAMIC_QUESTIONS,
  });

  const allQuestions = (questions?.data)?.Questionario || [];

  const patientDynamicQuestions = dynamicQuetionHelpers.filterByFlag(
    allQuestions,
    FlagQuestionTypeEnum.PATIENT,
  );

  const userDynamicQuestions: DynamicResponse[] = getValues(
    PatientFormDataPropertieNamesEnum.USER_DYNAMIC_QUESTIONS,
  );

  useEffect(() => {
    checkIfPatientNeedsCaregiver({
      userDynamicQuestions,
      setPatientLegalAgeError,
      birthDate: mode === PageModeEnum.CREATE
        ? medicineFormData?.birthDate : defaultValue?.birthDate,
    });
  }, [userDynamicQuestions, watch()]);

  useEffect(() => {
    setDefaultValues(defaultValue, setValue);
  }, [callApi]);

  const onSubmit = async (registrationFormData: any): Promise<void> => {
    if (patientLegalAgeError) {
      setTimeout(() => hideAlert(), 3000);
      return showAlert(
        patientFormAlertMessages.missingCaregiverError.title,
        patientFormAlertMessages.missingCaregiverError.subTitle,
        patientFormAlertMessages.missingCaregiverError.status,
      );
    }

    try {
      showLoader();
      await modeOptions[mode]?.dataDestiny(registrationFormData);
      hideLoader();
    } catch (error: any) {
      handleError(error);
    }
  };

  if (isLoadingDefaultValue && mode === PageModeEnum.UPDATE) {
    return (
      <div style={{ margin: '200px auto', marginLeft: '48%' }}>
        <CircularProgress />
      </div>
    );
  }

  const handleError = (error: any) => {
    const errorMessage = error?.response?.data?.message || '';
    hideLoader();
    setTimeout(() => hideAlert(), 3000);

    if (errorMessage === ErrorsTypeEnum.UnauthorizedPatient) {
      setTimeout(() => setTimeout(() => window.location.href = '/', 3200));
      return showAlert(
        patientFormAlertMessages.unauthorizedPatient.title,
        patientFormAlertMessages.unauthorizedPatient.subTitle,
        patientFormAlertMessages.unauthorizedPatient.status,
      );
    }

    return showAlert(
      patientFormAlertMessages.defaultError.title,
      errorMessage,
      patientFormAlertMessages.defaultError.status,
    );
  }

  const pageIsBlocked = mode === PageModeEnum.CREATE && !defaultValue?.cpf;
  if (pageIsBlocked) {
    push(RoutesEnum.PATIENT_LOGIN_PAGE);
    return <></>;
  }

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <PatientCodeAndBirthdayInputBox
            formData={formData}
            defaultValue={{
              cpf: defaultValue?.cpf,
              birthDate: defaultValue?.birthDate,
            }}
            isDisabled
          />
        </Box>
        <Box>
          <TextField
            variant="outlined"
            required
            label="Nome completo"
            style={isSmallScreen ? inputMUIDefaultStyles.normal : inputMUIDefaultStyles.large}
            error={!!errors.name}
            helperText={errors.name ? errors.name.message : ''}
            defaultValue={defaultValue?.name}
            {...register('name', patientNameValidation)}
            InputLabelProps={{
              ...inputLabelProps,
              shrink: !!getValues('name'),
            }}
          />
        </Box>
        <Box>
          <SelectInput
            title="Gênero"
            isRequired
            list={genderList}
            style={inputMUIDefaultStyles.normal}
            formData={formData}
            propertieFormName="gender"
            defaultValue={defaultValue?.gender}
          />
        </Box>
        <Box>
          <ContactBoxInputs formData={formData} defaultValue={defaultValue} />
        </Box>
        <Box>
          {modeOptions[mode]?.passwordComponent(formData)}
        </Box>
        <Box>
          {
            patientDynamicQuestions?.map((value, index) => (
              <DynamicQuestionsBox
                key={index}
                index={index}
                question={value}
                formData={formData}
                mainFieldName={PatientFormDataPropertieNamesEnum.USER_DYNAMIC_QUESTIONS}
                allQuestions={allQuestions}
                defaultValue={defaultValue?.user_dynamic_questions}
              />
            ))
          }
        </Box>
        <Box>
          <AddressBoxInputs formData={formData} defaultValue={defaultValue} />
        </Box>
        <br />
        <br />
        <Box>
          {modeOptions[mode]?.patientPreferenceComunicationInputComponent(formData)}
        </Box>
        <Box>
          {modeOptions[mode]?.termsComponent(formData)}
        </Box>
        <Box>
          {modeOptions[mode]?.captcha()}
        </Box>
        <InputsBox>
          {modeOptions[mode]?.cancelButton()}
          <Button type='default'>
            <button type="submit">
              {modeOptions[mode]?.buttonName || ''}
            </button>
          </Button>
        </InputsBox>
      </form>
    </Container>
  );
}
